import loader from 'uce-loader'
import '../Features/CustomElements/script.js'
import './main.styl'
import('../Components/DocumentDefault/script.js')

function kebabToCamel(input) {
  return capitalize(input.replace(/-([a-z])/g, function (g) {
      return g[1].toUpperCase()
  }))
}

function capitalize (s) {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

// const reqContext = require.context('../Components/', true, /\/script\.js$/)
// will load every custom elements via the path


loader({
  // by default it's document
  container: document.body,
  // invoked per each new custom-element name found
  on(newTag) {
    const ComponentName = kebabToCamel(newTag).replace('Flynt', '')
    if (ComponentName && window.FlyntData.componentsWithScript && window.FlyntData.componentsWithScript.includes(ComponentName)) {
      import(
        `../Components/${ComponentName}/script.js`
      )
    }
  }
});
